<template>
    <atom-dialog class="molecule-notice-dialog" :style="{transform}">
        <atom-notice v-bind="$attrs">
            <slot/>
        </atom-notice>
    </atom-dialog>
</template>



<script>
    export default {
        props: {
            position: {
                type: Number,
                default: 1
            }
        },

        computed: {
            transform() {
                const height = 64 // height of the element in px
                const position = this.position

                return position === 1 ?
                       undefined :
                       `translateY(-${(position - 1) * height}px)`
            }
        }
    }
</script>



<style>
    .molecule-notice-dialog {
        z-index: 200000; /* TEMP: work-around for dialog-polyfill */

        position: fixed;
        left: 0;
        bottom: 0;

        padding: 0;
        border: none;
        margin: 0;
        background-color: transparent;
    }
</style>
