<template>
    <component :is="tag"
               class="molecule-list-item" :class="{first, last, container: !route}"
               v-on="tag === 'button' ? $listeners : undefined">

        <component :is="route ? 'atom-link' : 'fragment'"
                   :to="route"
                   v-bind="$attrs"
                   :class="{container: route}">

            <slot name="left"/>

            <component :is="label ? 'div' : 'fragment'">
                <slot>
                    <atom-text v-if="text !== undefined" :text="text"/>
                </slot>

                <atom-text v-if="label" small light :text="label"/>
            </component>

            <slot v-if="right" name="right">
                <template v-if="$slots.append">
                    <atom-text v-if="hasOnlyText($slots.append)" light>
                        <slot name="append"/>
                    </atom-text>

                    <slot v-else name="append"/>
                </template>

                <atom-text v-else-if="append !== undefined" :text="append"/>

                <atom-icon v-if="route" icon="small-right-arrow" color="grey"/>
            </slot>
        </component>
    </component>
</template>



<script>
    import {hasOnlyText} from '../utilities/slot.js'

    // TODO: icons have wrong margin/padding

    // not a real fragment, as it cannot be used as the root node
    const fragment = {
        functional: true,
        render(create, context) {
            return context.children
        }
    }

    export default {
        components: {fragment},

        props: {
            tag: {
                type: String,
                default: 'li',
                validator(tag) {
                    return ['li', 'div', 'label', 'button'].includes(tag)
                }
            },

            route: {
                type: [String, Object]
            },

            text: {
                type: [String, Number]
            },

            label: {
                type: [String, Number]
            },

            right: {
                type: Boolean,
                default: true
            },

            append: {
                type: [String, Number]
            },

            first: {
                type: Boolean
            },

            last: {
                type: Boolean
            },

            // large: {
            //     type: Boolean
            // }
        },

        methods: {
            hasOnlyText
        }
    }
</script>



<style>
    button.molecule-list-item {
        appearance: none;
        border: none;
        font-family: inherit;
        cursor: pointer;

        width: 100%;
    }

    .molecule-list-item {
        background-color: white;
        border-radius: 16px;

        margin-bottom: 12px;
    }

    .molecule-list-item.first {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        margin-bottom: 0;
    }

    .molecule-list-item + .molecule-list-item,
    .molecule-list + .molecule-list-item.last {
        box-shadow: 16px 0px white inset, 0px 1px var(--light-grey) inset;
    }

    .molecule-list-item + .molecule-list-item:not(.last) {
        border-radius: 0;

        margin-bottom: 0;
    }

    .molecule-list-item.last {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .molecule-list-item.container,
    .molecule-list-item .atom-link.container {
        display: flex;
        align-items: center;

        padding: 12px 16px 14px;
    }

    /*
    .molecule-list-item.large {
        margin-bottom: 16px;
    }

    .molecule-list-item.large.container,
    .molecule-list-item.large .atom-link.container {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    */

    .molecule-list-item.container > :first-child,
    .molecule-list-item .atom-link.container > :first-child {
        margin-right: auto;
    }

    .molecule-list-item.container > .atom-icon:first-child,
    .molecule-list-item .atom-link.container > .atom-icon:first-child {
        margin-right: 4px;
    }

    .molecule-list-item.container > .atom-icon:first-child + *,
    .molecule-list-item .atom-link.container > .atom-icon:first-child + * {
        margin-right: auto;
    }


    .molecule-list-item .atom-text {
        margin: 0;
    }

    button.molecule-list-item .atom-text:only-child {
        color: var(--red);
        margin: 0 auto;
    }


    .molecule-list-item .atom-button {
        pointer-events: none;
    }

    .molecule-list-item .atom-input {
        margin: 0;
    }

    /* TEMP: until a radio/checkbox component is made */
    .molecule-list-item input[type="radio"]:required:invalid,
    .molecule-list-item input[type="checkbox"]:required:invalid {
        box-shadow: none;
    }
</style>
