<template>
    <div class="atom-text-area" :class="{grey, grow}">
        <textarea class="field"
                  v-bind="$attrs" v-on="listeners"
                  ref="field"
                  :rows="minLines"
                  v-text="value"/>
    </div>
</template>



<script>
    // inspired by https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/

    // NOTE: these must be the same as defined in the CSS for the textarea
    const lineHeight = 22 // px
    const padding = 26 // px (padding-top + padding-bottom)

    function isTextNode(item) {
        return item.tag === undefined
    }

    // transforms each line like:
    // "    string   with spaces  " --> "string   with spaces"
    function trimSpaces(string) {
        return string.replace(/^ +| +$/gm, '')
    }

    export default {
        inheritAttrs: false,

        props: {
            value: {
                type: [String, Number]
            },

            grow: {
                type: Boolean,
                default: true
            },

            'min-lines': {
                type: Number,
                default: 1
            },

            'max-lines': {
                type: Number
            },

            grey: {
                type: Boolean
            }
        },

        computed: {
            listeners() {
                return {
                    ...this.$listeners,

                    input: event => {
                        if (this.grow) {
                            this.setReplicaContent()
                        }

                        this.$emit('input', event.target.value)
                    }
                }
            }
        },

        watch: {
            value() {
                if (this.grow) {
                    this.setReplicaContent()
                }
            }
        },

        methods: {
            setReplicaContent() {
                this.$el.dataset.replicaContent = this.$refs.field.value
            },

            clear() {
                this.$refs.field.value = ''

                if (this.grow) {
                    this.setReplicaContent()
                }

                this.$emit('input', '')
            }
        },

        mounted() {
            const slot = this.$slots.default
            const field = this.$refs.field

            const slotItem = slot === undefined ? undefined : slot[0]

            const hasSlotItem = slotItem !== undefined
            const setMaxHeight = this.maxLines !== undefined

            if (this.value === undefined && hasSlotItem && isTextNode(slotItem)) {
                field.value = trimSpaces(slotItem.text.trim())
                this.$emit('input', field.value)
            }

            if (setMaxHeight) {
                this.$el.style.setProperty(
                    '--atom-text-area-max-height',
                    ((this.maxLines * lineHeight) + padding) + 'px'
                )
            }

            if (this.grow) {
                this.setReplicaContent()
            }
        }
    }
</script>



<style>
    .atom-text-area {
        margin-bottom: 12px;

        --atom-text-area-max-height: none;
    }

    .atom-text-area.grow {
        display: grid;
    }

    .atom-text-area .field {
        font-family: inherit;
        font-weight: inherit;
        resize: none;
        margin: 0;
        width: 100%;
        border: none;
        box-shadow: none;
        outline: none;

        color: var(--black);
        caret-color: var(--red);

        /* oh Firefox...
           "FF adds height to the TextArea to reserve place for scroll-bars.",
           setting "overflow-x: hidden;" removes it
           - https://stackoverflow.com/a/22700700/3436707
           - https://bugzilla.mozilla.org/show_bug.cgi?id=33654 */
        overflow-x: hidden;

        /* Firefox also has weird scrollbar issues with short scrollbars,
           which are common on textareas,
           unless it is thin! */
        scrollbar-width: thin;

        border-radius: 12px;
        background-color: white;
        transition: background-color 0.15s;
    }

    .atom-text-area.grey .field {
        background-color: var(--super-light-grey);
    }

    .atom-text-area.grey .field:hover {
        background-color: var(--super-light-grey-hover);
    }

    .atom-text-area.grey .field:active {
        background-color: var(--super-light-grey-pressed);
    }

    .atom-text-area .field::placeholder {
        opacity: 1;

        color: var(--dark-grey);
        font-weight: 400;
    }

    .atom-text-area .field,
    .atom-text-area.grow::after {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;

        word-wrap: anywhere;

        box-sizing: border-box;
        padding: 12px 16px 14px;
    }

    .atom-text-area.grow::after {
        content: attr(data-replica-content) " ";
        white-space: pre-wrap;
        visibility: hidden;
    }

    .atom-text-area.grow::after,
    .atom-text-area.grow .field {
        grid-row: 1;
        grid-column: 1;

        max-height: var(--atom-text-area-max-height);
    }
</style>
