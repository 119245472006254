<template>
    <component :is="route ? 'atom-link' : 'button'"
               class="atom-button" :class="{grey, 'full-width': fullWidth, center, large, icon}"
               :type="route ? undefined : type"
               :disabled="disabled"
               :to="route"
               :external="route ? external : undefined"
               :replace="route ? replace : undefined"
               v-on="route ? undefined : $listeners">

        <atom-icon v-if="icon" :icon="icon" :color="disabled ? 'grey' : (grey ? 'black' : 'white')"/>

        <template v-if="text || isOnlyText">
            <span class="text">
                <slot>{{text}}</slot>
            </span>
        </template>

        <slot v-else/>
    </component>
</template>



<script>
    import {hasOnlyText} from '../utilities/slot.js'

    export default {
        props: {
            type: {
                // overwrites the default dynamic unspecified type of buttons,
                // as it can turn into "submit" if used in forms,
                // but you should always specify that explicity instead.
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
                type: String,
                default: 'button'
            },

            disabled: {
                type: Boolean
            },

            external: {
                type: Boolean
            },

            replace: {
                type: Boolean
            },

            route: {
                type: [String, Object]
            },

            grey: {
                type: Boolean
            },

            'full-width': {
                type: Boolean
            },

            center: {
                type: Boolean
            },

            large: {
                type: Boolean
            },

            icon: {
                type: String
            },

            text: {
                type: [String, Number]
            }
        },

        computed: {
            isOnlyText() {
                return hasOnlyText(this.$slots.default)
            }
        },

        created() {
            if (this.route && this.disabled) {
                throw new Error(
                    'Invalid combination of prop "route" and "disabled". Links cannot be disabled.'
                )
            }
        }
    }
</script>



<style>
    button.atom-button {
        appearance: none;
        border: none;
        font-family: inherit;

        cursor: pointer;
    }

    .atom-button {
        display: flex;
        align-items: center;

        width: max-content;

        padding: 10px 16px 12px;
        box-sizing: border-box;

        border-radius: 12px;
        background-color: var(--red);

        transition: background-color 0.15s;
    }

    .atom-button:hover {
        background-color: var(--red-hover);
    }

    .atom-button:active {
        background-color: var(--red-pressed);
    }

    button.atom-button:disabled {
        cursor: inherit;
        background-color: var(--light-grey);
    }

    button.atom-button.grey:disabled {
        color: var(--grey);
        background-color: var(--super-light-grey);
    }

    .atom-button.grey {
        background-color: var(--super-light-grey);
    }

    .atom-button.grey:hover {
        background-color: var(--super-light-grey-hover);
    }

    .atom-button.grey:active {
        background-color: var(--super-light-grey-pressed);
    }

    .atom-button.full-width {
        justify-content: center;

        width: 100%;

        margin-bottom: 24px;
    }

    .atom-button.center {
        justify-content: center;
    }

    .atom-button.icon {
        padding: 8px;
    }

    .atom-button.large.icon {
        padding: 12px;
    }


    .atom-button > .text {
        color: white;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
    }

    .atom-button.large > .text {
        font-size: 16px;
        line-height: 22px;
    }

    .atom-button.grey > .text {
        color: black;
    }

    .atom-button:disabled > .text {
        color: inherit;
    }

    .atom-button > .atom-icon + .text {
        margin-left: 5px;
        padding-right: 7px;
    }
</style>
