export function hasOnlyText(slot) {
    return slot === undefined ?
        false :
        slot.every(function(item) {
            return item.tag === undefined
        })
}



export function isTextNode(item) {
    return item.tag === undefined
}



export function isContent(item) {
    return item.tag === undefined ?
        item.text.trim().length !== 0 :
        true
}
