<template>
    <atom-dialog class="molecule-modal-dialog" backdrop
                 ref="dialog"
                 v-on="$listeners">

        <slot name="header" v-bind="{cancel}">
            <header class="header">
                <slot name="header-left"/>
                <atom-button icon="cross" grey @click="cancel" aria-label="Cancel"/>
            </header>
        </slot>

        <slot v-bind="{confirm, cancel}"/>
    </atom-dialog>
</template>



<script>
    export default {
        methods: {
            confirm(event) {
                this.$emit('confirm', event)
                this.$refs.dialog.close()
            },

            cancel() {
                this.$emit('cancel')
                this.$refs.dialog.close()
            },

            close() {
                this.$refs.dialog.close()
            }
        }
    }
</script>



<style>
    .molecule-modal-dialog {
        display: flex;
        flex-direction: column;

        border: none;

        padding: 16px;
        box-sizing: border-box;

        width: 100%;
        max-width: 311px;

        border-radius: 16px;
    }


    .molecule-modal-dialog .header {
        display: flex;

        align-items: center;
        justify-content: space-between;

        margin-bottom: 12px;
    }


    .molecule-modal-dialog .header .atom-title {
        margin: 0;
    }


    .molecule-modal-dialog .header .atom-button:last-of-type {
        margin-left: auto;
    }


    .molecule-modal-dialog .header .atom-background:first-of-type {
        margin-right: 16px;
    }


    .molecule-modal-dialog .atom-form {
        margin-top: 32px;
    }

    .molecule-modal-dialog .atom-text + .atom-form {
        margin-top: 16px;
    }

    .molecule-modal-dialog .atom-label + .atom-form {
        margin-top: 0;
    }

    .molecule-modal-dialog .header + .atom-title {
        margin-bottom: 12px;
    }


    .molecule-modal-dialog .atom-text:last-child {
        margin-bottom: 0;
    }


    .molecule-modal-dialog .atom-text:last-of-type .atom-icon:first-child {
        margin-left: -8px;
    }
</style>
