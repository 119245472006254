<template>
    <component :is="tag" class="molecule-time-input" :class="{grey}">
        <atom-text v-if="label" :text="label"/>

        <!--
             the pattern is only used when type="time" fallbacks into type="text"
             on non-iOS Safari, for example.
        -->
        <atom-input v-bind="$attrs" v-on="$listeners"
                    :value="value"
                    :type="type"
                    :grey="grey"
                    pattern="([0-1][0-9]|20|21|22|23):[0-5][0-9]"
                    placeholder="HH:MM"/>
    </component>
</template>



<script>
    export default {
        inheritAttrs: false,

        props: {
            tag: {
                type: String,
                default: 'label',
                validator(tag) {
                    return ['label', 'div'].includes(tag)
                }
            },

            value: {
                type: String,
                // TODO: check if this is works on Safari
                // default: '--:--'
            },

            type: {
                type: String,
                default: 'time',
                validator(value) {
                    return value === 'time'
                }
            },

            grey: {
                type: Boolean
            },

            label: {
                // placed inline - do not wrap the component in other labels if used
                type: String
            }
        }
    }
</script>



<style>
    .molecule-time-input {
        display: flex;
        align-items: center;

        border-radius: 12px;
        background-color: white;

        margin-bottom: 12px;
    }

    .molecule-time-input.grey {
        background-color: var(--super-light-grey);
    }

    .molecule-time-input.grey:hover {
        background-color: var(--super-light-grey-hover);
    }

    .molecule-time-input.grey:active {
        background-color: var(--super-light-grey-pressed);
    }


    .molecule-time-input .atom-text {
        margin: 0;
        margin-left: 16px;
    }


    .molecule-time-input .atom-input {
        -webkit-appearance: none;
        appearance: none;

        background-color: transparent;
        width: auto;
        margin-left: auto;
    }
</style>
