<template>

    <div class="molecule-player" :class="{playing}">
        <!-- @mouseup needed because of Safari for macOS having broken change events -->
        <input class="seekbar" type="range" aria-hidden="true"
               :style="{'background-image': progressBar}"
               min="0" :max="duration" step="any" :value="currentTime"
               ref="seekbar"
               @input="input"
               @mouseup="change"
               @change="change">

        <atom-text :text="played" aria-hidden="true" />
        <atom-text :text="remaining" aria-hidden="true" />

        <audio :src="url"
               preload="metadata"
               ref="player" v-on="$listeners"
               @play="started"
               @pause="paused"
               @loadedmetadata="loaded"
               @timeupdate="update"></audio>

        <slot name="controls">
            <atom-flex rules="auto, auto" space-between>
                <atom-button v-if="playing === false" icon="play" text="Play" @click="play" />
                <atom-button v-else icon="pause" text="Stop" @click="pause" />

                <slot/>
                <atom-button
                  v-if="duplicate"
                  grey
                  icon="duplicate"
                  text="Duplicate"
                  @click="dupe" />
            </atom-flex>
        </slot>
    </div>
</template>



<script>
    // https://github.com/sampotts/rangetouch
    // needed to make range input smooth in Safari for iOS,
    // check demo at https://rangetouch.com/
    import RangeTouch from 'rangetouch'

    // returns in m:ss format, i.e "7:03"
    function formatSeconds(seconds) {
        const m = Math.floor(seconds / 60).toString()
        const ss = Math.floor(seconds % 60).toString().padStart(2, '0')

        return m + ':' + ss
    }

    export default {
        props: {
            url: {
                type: String,
                required: true
            },
            id: {
              type: String,
            },
            duplicate: {
                type: Boolean
            }
        },

        data() {
            return {
                seeking: false,
                playing: false,
                currentTime: 0,
                duration: 0
            }
        },

        computed: {
            playedPercent() {
                const {duration, currentTime} = this

                const divided = duration === 0 ?
                                0 :
                                (100 * currentTime) / duration

                return divided.toFixed(2)
            },

            played() {
                return formatSeconds(this.currentTime)
            },

            remaining() {
                return formatSeconds(this.duration - this.currentTime)
            },

            progressBar() {
                const percent = this.playedPercent.toString() + '%'

                const colored = 'var(--red) 0%, var(--red) ' + percent
                const uncolored = 'var(--light-grey) ' + percent + ', var(--light-grey) 100%'

                return `linear-gradient(to right, ${colored}, ${uncolored})`
            },
        },

        methods: {
            input() {
                this.seeking = true

                this.currentTime = parseFloat(this.$refs.seekbar.value).toFixed(2)
            },

            change() {
                this.seeking = false

                this.$refs.player.currentTime = this.$refs.seekbar.value
            },

            started() {
                this.playing = true
            },

            paused() {
                this.playing = false
            },

            loaded() {
                if (this.$refs.player) {
                    this.duration = this.$refs.player.duration.toFixed(2)
                }
            },

            update() {
                if (this.seeking === false && this.$refs.player) {
                    this.currentTime = Math.round(this.$refs.player.currentTime)
                }
            },

            play() {
                this.$refs.player.play()
            },

            pause() {
                this.$refs.player.pause()
            },
            dupe() {
              this.$router.push({
                name: 'duplicate',
                params: {id: this.id}
              })
            }
        },

        mounted() {
            // https://github.com/sampotts/rangetouch#options
            const instance = new RangeTouch(this.$refs.seekbar, {addCSS: false})

            this.$once('hook:beforeDestroy', () => instance.destroy())
        }
    }
</script>



<style>
    .molecule-player {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 14px 0 16px;
    }


    .molecule-player .seekbar {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0;

        width: 100%;
        height: 4px;

        padding: 14px 0;
        background-clip: content-box;

        margin: 0 8px -6px;
    }

    .molecule-player .seekbar:focus {
        outline: none; /* TEMP: until a proper focus state is implemented */
    }

    /* these must be seperated, else they break each other */
    .molecule-player .seekbar::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border: none;

        height: 8px;
        width: 8px;

        background-color: var(--grey);
        border-radius: 50%;

        transition: background-color 0.33s;
    }

    .molecule-player.playing .seekbar::-webkit-slider-thumb {
        background-color: var(--red);
    }

    .molecule-player .seekbar::-moz-range-thumb {
        border: none;

        height: 8px;
        width: 8px;

        background-color: var(--grey);
        border-radius: 50%;

        transition: background-color 0.33s;
    }

    .molecule-player.playing .seekbar::-moz-range-thumb {
        background-color: var(--red);
    }


    .molecule-player .atom-text {
        margin: 0 8px;
    }


    .molecule-player > .atom-flex {
        width: 100%;

        margin-top: 32px;
    }
</style>
