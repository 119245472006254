<template>
    <component :is="tag" class="atom-text" :class="{center, small, light}">
        <template v-if="text !== undefined">{{text}}</template>

        <slot/>
    </component>
</template>



<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'span',
                validator(tag) {
                    return ['span', 'small', 'p', 'time'].includes(tag)
                }
            },

            center: {
                type: Boolean,
                default: false,
            },

            small: {
                type: Boolean,
                default: false
            },

            light: {
                type: Boolean,
                default: false
            },

            text: {
                type: [String, Number]
            }
        }
    }
</script>


<style>
    .atom-text {
        display: block;

        color: var(--black);
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;

        margin: 0 8px 24px;
    }

    .atom-text.center {
        text-align: center;
    }

    .atom-text.small {
        font-size: 14px;
        line-height: 20px;
    }

    .atom-text.light {
        color: var(--dark-grey);
        font-weight: 400;
    }


    .atom-text.small .atom-link {
        text-decoration: underline;

        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
    }


    .atom-text .atom-icon {
        display: inline-block;
        margin-bottom: -7px;
    }


    span.atom-title + .atom-text {
        margin: 0;
    }
</style>
