<template>
    <label class="molecule-file-input" :class="{grey, 'no-label': label === false}"
           :aria-title="title" :title="title">
        <atom-text v-if="label !== false && (selected || label)" :text="selected ? selected.name : label"/>

        <atom-input v-bind="$attrs" ref="input" :type="type" @change="change"/>

        <atom-icon icon="import" :color="iconColor"/>
    </label>
</template>



<script>
    // TODO: add support for "max-size"
    //  - check out https://design-system.service.gov.uk/components/file-upload/

    export default {
        inheritAttrs: false,

        data() {
            return {
                selected: undefined
            }
        },

        props: {
            type: {
                type: String,
                default: 'file',
                validator(value) {
                    return value === 'file'
                }
            },

            grey: {
                type: Boolean
            },

            label: {
                // placed inline - do not wrap the component in other labels if used
                type: [String, Boolean],
                default: undefined
            },

            title: {
                type: String
            },

            'icon-color': {
                type: String,
                default: 'red'
            }
        },

        methods: {
            change() {
                const input = this.$refs.input.$el

                const selected = input.files.length ?
                                 input.files[0] :
                                 undefined

                this.selected = selected

                this.$emit('change', this.selected)

                if (selected) {
                    this.$emit('select', this.selected)
                }
            },

            reset() {
                this.$refs.input.clear()
                this.change()
            }
        }
    }
</script>



<style>
    .molecule-file-input {
        cursor: pointer;

        display: grid;
        grid-template-columns: 1fr auto;

        align-items: center;

        border-radius: 12px;
        background-color: white;

        margin-bottom: 12px;
    }

    .molecule-file-input.no-label {
        grid-template-columns: auto;
        justify-items: center;

        height: 40px;
        width: 40px;

        margin-bottom: 0;
    }

    .molecule-file-input.grey {
        background-color: var(--super-light-grey);
    }

    .molecule-file-input.grey:hover {
        background-color: var(--super-light-grey-hover);
    }

    .molecule-file-input.grey:active {
        background-color: var(--super-light-grey-pressed);
    }


    .molecule-file-input .atom-input {
        z-index: -1;

        grid-row: 1;
        grid-column: 1 / 3;
    }

    .molecule-file-input.no-label .atom-input {
        padding: 0;
        grid-column: 1;
    }


    .molecule-file-input .atom-text {
        pointer-events: none;

        margin: 0;
        margin-left: 16px;

        grid-row: 1;
        grid-column: 1;

        font-weight: 400;
    }


    .molecule-file-input .atom-icon {
        pointer-events: none;

        margin-right: 8px;

        grid-row: 1;
        grid-column: 2;
    }

    .molecule-file-input.no-label .atom-icon {
        margin-right: 0;
        grid-column: 1;
    }
</style>
