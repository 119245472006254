<template>
    <div class="molecule-record-input">
        <template v-if="supported === false">
            <slot name="unsupported">
                <atom-text text="The MediaRecorder API is not supported in your browser"/>
            </slot>
        </template>

        <template v-else>
            <atom-title class="duration" tag="time" size="large" :text="formatedDuration"/>


            <atom-text v-if="access === undefined || (access !== true && requesting === true)"
                       text="Waiting for microphone access"/>

            <atom-text v-else-if="access === false"
                       text="Access is blocked - try to tweak your permissions"/>

            <atom-text v-else class="max-length-info"
                       :text="'Max ' + maxlengthFormated"/>


            <atom-button v-if="access !== true"
                         text="Grant microphone access"
                         :disabled="requesting"
                         @click="getStreamAccess"/>

            <atom-button v-else-if="recording === false"
                         text="Start recording"
                         @click="record"/>

            <atom-button v-else
                         text="Stop recording"
                         @click="stop"/>
        </template>
    </div>
</template>



<script>
    // all modern browsers supports playback of m4a (audio/mp4) and mp3 (audio/mpeg) files,
    // but none, except Safari with m4a, supports creating them from a MediaRecorder recording

    // m4a is superior, but mp3 is a great fallback

    // until m4a is widely supported to record with (native or with libraries),
    // mp3-mediarecorder is used to provide mp3 fallback recording support

    import {Mp3MediaRecorder} from 'mp3-mediarecorder'
    import Mp3RecorderWorker from '../utilities/mp3-mediarecorder.worker.js'

    // returns in m:ss format, i.e "7:03"
    function formatSeconds(seconds) {
        const m = Math.floor(seconds / 60).toString()
        const ss = Math.floor(seconds % 60).toString().padStart(2, '0')

        return m + ':' + ss
    }

    export default {
        props: {
            // seconds
            maxlength: {
                type: Number,
                default: 120,
                validator(value) {
                    return value >= 1
                }
            },

            hasAccess: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                access: undefined,
                requesting: false,

                stream: undefined,
                recorder: undefined,

                recorded: undefined,
                recording: false,
                duration: 0
            }
        },

        computed: {
            supported() {
                return navigator.mediaDevices !== undefined &&
                       navigator.mediaDevices.getUserMedia !== undefined
            },

            formatedDuration() {
                return formatSeconds(this.duration)
            },

            maxlengthFormated() {
                const seconds = this.maxlength

                const minutes = (seconds < 60) ? 0 : Math.round(seconds / 60)

                return minutes === 0 ?
                       (seconds + ' second' + (seconds === 1 ? '' : 's')) :
                       (minutes + ' minute' + (minutes === 1 ? '' : 's'))
            }
        },

        methods: {
            async getStream() {
                this.requesting = true

                try {
                    const stream = await navigator.mediaDevices.getUserMedia({audio: true})

                    this.access = true
                    this.stream = stream
                }

                catch(error) {
                    if (error.name === 'NotAllowedError') {
                        this.access = false
                    }

                    else {
                        throw error
                    }
                }

                finally {
                    this.requesting = false
                }
            },

            async getStreamAccess() {
                await this.getStream()

                this.$emit('access')

                this.closeStream()
            },

            closeStream() {
                if (this.stream !== undefined) {
                    this.stream.getAudioTracks().forEach(function(track) {
                        track.stop()
                    })

                    this.stream = undefined
                }
            },

            setRecorded(event) {
                const blob = event.data

                this.recorded = blob
                this.$emit('recorded', this.recorded)
            },

            async record() {
                const timeout = setTimeout(() => {
                    this.stop()
                }, (this.maxlength + 1) * 1000)

                const interval = setInterval(() => {
                    this.duration += 1
                }, 1000)

                if (this.stream === undefined) {
                    await this.getStream()
                }

                if (MediaRecorder.isTypeSupported('audio/mp4')) {
                    this.recorder = new MediaRecorder(this.stream, {
                        mimeType: 'audio/mp4'
                    })
                }

                else {
                    this.recorder = new Mp3MediaRecorder(this.stream, {
                        worker: Mp3RecorderWorker()
                    })
                }

                this.recorder.start()
                this.recording = true
                this.duration = 0

                this.$emit('recording')

                this.recorder.addEventListener('dataavailable', this.setRecorded, {once: true})

                this.recorder.addEventListener(
                    'stop',
                    () => {
                        clearTimeout(timeout)
                        clearInterval(interval)

                        this.recording = false
                        this.recorder = undefined

                        this.closeStream()
                    },
                    {once: true}
                )
            },

            stop() {
                if (this.recording) {
                    this.recorder.stop()
                }
            },

            clear() {
                if (this.recorder) {
                    this.recorder.removeEventListener('dataavailable', this.setRecorded)
                }

                this.stop()

                this.duration = 0
                this.recorded = undefined

            }
        },

        created() {
            if (this.hasAccess) {
                this.access = true
            }
        },

        beforeDestroy() {
            this.clear()
        }
    }
</script>



<style>
    .molecule-record-input {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .molecule-record-input .atom-title {
        margin-bottom: 0;
    }

    .molecule-record-input .atom-text {
        margin: 0;
    }

    .molecule-record-input .max-length-info {
        color: var(--red);
    }

    .molecule-record-input .atom-button {
        margin-top: 32px;
    }
</style>
