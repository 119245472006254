<template>
    <nav class="molecule-select">
        <atom-link v-for="item in items" :key="item.route"
                   class="item"
                   exact
                   replace
                   active-class="selected"
                   :to="item.route" :text="item.label"/>
    </nav>
</template>



<script>
    // only nav support for now

    export default {
        props: {
            items: {
                type: Array,
                required: true
            }
        }
    }
</script>



<style>
    .molecule-select {
        display: flex;
        width: max-content;

        padding: 4px;

        border-radius: 12px;
        background-color: var(--super-light-grey);
    }


    .molecule-select .atom-link {
        color: #000000;
        font-size: 14px;
        line-height: 18px;
    }


    .molecule-select .item {
        padding: 6px 15px 8px 16px;
        border-radius: 8px;
    }

    .molecule-select .item.selected {
        background-color: white;
    }
</style>
