<template>
    <component :is="tag" class="atom-background" :class="{'large-corner-radius': largeCornerRadius}">
        <slot/>
        <div class="background" aria-hidden="true" :style="style"></div>
    </component>
</template>



<script>
    const globalStyles = window.getComputedStyle(document.documentElement)

    export default {
        props: {
            tag: {
                type: String,
                default: 'div'
            },

            color: {
                type: String,
                required: true,
                validator(value) {
                    return globalStyles.getPropertyValue('--' + value) ? true : false
                }
            },

            opacity: {
                type: String,
                validator(value) {
                    const number = parseInt(value)

                    return number >= 0 && number <= 1
                }
            },

            'large-corner-radius': {
                type: Boolean
            }
        },

        computed: {
            style() {
                return {
                    'background-color': globalStyles.getPropertyValue('--' + this.color),
                    opacity: this.opacity
                }
            }
        }
    }
</script>



<style>
    .atom-background {
        position: relative;
    }

    .atom-background .background {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: -1;

        border-radius: 8px;
    }

    .atom-background.large-corner-radius .background {
        border-radius: 12px;
    }


    .atom-background > .atom-icon {
        padding: 8px;
    }
</style>
