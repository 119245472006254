<template>
    <div class="atom-notice" :class="[type]">
        <atom-icon :icon="icon" color="white" />

        <slot>{{text}}</slot>
    </div>
</template>



<script>
    export default {
        props: {
            text: {
                type: String
            },

            type: {
                type: String,
                required: true,
                validator(value) {
                    return ['info', 'success', 'loading', 'warning'].includes(value)
                }
            }
        },

        computed: {
            icon() {
                switch(this.type) {
                    case 'info': return 'warning'
                    case 'success': return 'check-filled'
                    case 'loading': return 'loading'
                    case 'warning': return 'warning'
                }
            }
        }
    }
</script>



<style>
    .atom-notice {
        display: flex;
        width: max-content;
        margin: 0 0 24px 24px;

        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;

        padding: 11px 64px 8px 8px;
        background-color: var(--dark-grey);
        border-radius: 12px;
    }

    .atom-notice.warning {
        background-color: var(--red);
    }


    .atom-notice .atom-icon {
        margin-top: -3px;
        margin-right: 4px;
    }

    .atom-notice.loading .atom-icon {
        animation: atom-notice-spin 1s infinite linear;
    }

    @keyframes atom-notice-spin {
        from {
            transform: rotate(0deg)
        }

        to {
            transform: rotate(360deg)
        }
    }
</style>
