<template>
    <atom-background class="atom-tag" tag="small" color="yellow" opacity="0.32">
        <template v-if="text">{{text}}</template>
        <slot/>
    </atom-background>
</template>



<script>
    export default {
        props: {
            text: {
                type: [String, Number]
            }
        }
    }
</script>



<style>
    .atom-tag {
        display: flex;
        align-items: center;

        width: max-content;

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        padding: 4px 10px 6px 12px;
    }


    .atom-tag .atom-icon {
        margin: -1px 0 -3px -9px;
    }
</style>
