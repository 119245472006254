<template>
    <form class="atom-form" v-on="listeners">
        <slot/>
    </form>
</template>

<script>
    export default {
        props: {
            disabled: Boolean
        },

        computed: {
            listeners() {
                return {
                    ...this.$listeners,

                    submit: event => {
                        event.preventDefault()

                        if (this.disabled === false) {
                            this.$emit('submit', event)
                        }
                    }
                }
            }
        }
    }
</script>
