<template>
    <div class="layout-main" :class="[width + '-width']">
        <molecule-header :title="headerTitle" :label="headerLabel" :align="headerAlign">
            <slot name="header-left" slot="left"/>
            <slot name="header-right" slot="right"/>
        </molecule-header>

        <main class="content">
            <template v-if="background">
                <div class="wrapper" v-if="$slots.top">
                    <slot name="top"/>
                </div>

                <div class="background">
                    <slot name="pre-wrapper"/>

                    <div class="wrapper">
                        <slot/>
                    </div>
                </div>
            </template>

            <template v-else>
                <slot name="pre-wrapper"/>

                <div class="wrapper">
                    <slot/>
                </div>
            </template>
        </main>

        <footer class="footer" :class="{grey: background}" v-if="$slots.footer">
            <div class="wrapper">
                <slot name="footer"/>
            </div>
        </footer>
    </div>
</template>



<script>
    export default {
        props: {
            width: {
                type: String,
                default: 'medium',
                validator(value) {
                    return ['small', 'medium', 'large'].includes(value)
                }
            },

            background: {
                type: Boolean
            },

            'header-title': {
                type: String
            },

            'header-label': {
                type: String
            },

            'header-align': {
                type: String
            }
        }
    }
</script>



<style>
    .layout-main {
        display: flex;
        flex-direction: column;

        min-height: 100vh;
    }


    /* 1080px + margin-x's */
    @media (min-width: 1112px) {
        .layout-main .molecule-header,
        .layout-main > .footer > .wrapper {
            width: 100%;
            max-width: 1080px;

            margin-left: auto;
            margin-right: auto;
        }
    }


    .layout-main > .content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
    }


    .layout-main > .content > .wrapper,
    .layout-main > .content > .background > .wrapper {
        display: flow-root;

        margin-left: 16px;
        margin-right: 16px;
    }

    /* 390px + margin-x's */
    @media (min-width: 422px) {
        .layout-main.small-width > .content > .wrapper,
        .layout-main.small-width > .content > .background > .wrapper {
            width: 100%;
            max-width: 390px;

            margin-left: auto;
            margin-right: auto;
        }
    }

    /* 460px + margin-x's */
    @media (min-width: 492px) {
        .layout-main.medium-width > .content > .wrapper,
        .layout-main.medium-width > .content > .background > .wrapper {
            width: 100%;
            max-width: 460px;

            margin-left: auto;
            margin-right: auto;
        }
    }

    /* 720px + margin-x's */
    @media (min-width: 752px) {
        .layout-main.large-width > .content > .wrapper,
        .layout-main.large-width > .content > .background > .wrapper {
            width: 100%;
            max-width: 720px;

            margin-left: auto;
            margin-right: auto;
        }
    }


    .layout-main > .content > .background {
        flex-grow: 1;

        padding-top: 12px;

        background-color: var(--light-grey);
    }



    .layout-main > .footer {
        padding: 12px 16px;
        text-align: center;
    }

    .layout-main > .footer.grey {
        background-color: var(--light-grey);
    }

    .layout-main > .footer .atom-text {
        margin: 0;
    }
</style>
