import {kebabCase} from './utilities/string.js'


export function register(instance, components) {
    Object
        .entries(components)
        .forEach(function([key, value]) {
            instance.component(kebabCase(key), value)
        })
}


export function namespaced(namespace, components) {
    return Object.fromEntries(
        Object
            .entries(components)
            .map(function([key, value]) {
                return [
                    (namespace + '-') + key,
                    value
                ]
            })
    )
}
