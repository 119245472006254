<template>
    <component :is="tag" class="atom-title" :class="{[size]: size, center, italic}">
        <slot>{{text}}</slot>
    </component>
</template>



<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'h1',
                validator(tag) {
                    return ['h1', 'h2', 'h3', 'h4', 'h5', 'span', 'time'].includes(tag)
                }
            },

            size: {
                type: String,
                validator(value) {
                    const allowed = [
                        undefined,
                        'xl',
                        'large',
                        'medium',
                        'small',
                        'tiny'
                    ]

                    return allowed.includes(value)
                }
            },

            center: {
                type: Boolean,
                default: false,
            },

            italic: {
                type: Boolean
            },

            text: {
                type: [String, Number]
            }
        }
    }
</script>



<style>
    span.atom-title {
        display: block;
    }

    .atom-title {
        margin: 0;
        font-size: inherit;

        font-weight: 800;
        color: var(--black);

        margin: 32px 8px;
    }

    .atom-title.center {
        text-align: center;
    }

    .atom-title.italic {
        font-style: italic;
        font-weight: 600;
    }

    h1.atom-title, .atom-title.xl {
        font-size: 32px;
        line-height: 36px;
    }

    h2.atom-title, .atom-title.large {
        font-size: 26px;
        line-height: 32px;
    }

    h3.atom-title, .atom-title.medium {
        font-size: 20px;
        line-height: 26px;
    }

    h4.atom-title, .atom-title.small {
        font-size: 16px;
        line-height: 20px;
    }

    h5.atom-title, .atom-title.tiny {
        font-size: 14px;
        line-height: 18px;
    }

    span.atom-title {
        margin: 0;
    }


    .atom-title .atom-icon {
        display: inline-block;
        margin-bottom: -5px;
    }
</style>
