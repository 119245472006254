<template>
    <component :is="tag" class="molecule-list" :class="{appendable}">
        <template v-if="items">
            <molecule-list-item v-for="(item, index) in items" :key="index" v-bind="item">
                <slot :item="item" name="left" slot="left"/>
                <slot :item="item" slot="default"/>
                <slot :item="item" name="right" slot="right"/>
                <slot :item="item" name="append" slot="append"/>
            </molecule-list-item>
        </template>

        <slot v-else/>
    </component>
</template>



<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'ul',
                validator(tag) {
                    return ['ul', 'ol', 'div', 'section', 'fieldset'].includes(tag)
                }
            },

            items: {
                type: Array
            },

            appendable: {
                type: Boolean
            }
        }
    }
</script>



<style>
    ul.molecule-list,
    ok.molecule-list {
        list-style: none;
        padding: 0;
    }

    fieldset.molecule-list {
        border: 0;
        padding: 0;
    }

    .molecule-list {
        margin: 0 0 12px;
    }

    .molecule-list.appendable {
        margin-bottom: 0;
    }


    .molecule-list .molecule-list-item {
        margin: 0;
    }

    .molecule-list .molecule-list-item:first-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .molecule-list:not(.appendable) .molecule-list-item:last-of-type {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
    }
</style>
