<script>
    import {isContent, isTextNode} from '../utilities/slot.js'

    export default {
        props: {
            tag: {
                type: String,
                default: 'div'
            },

            align: {
                type: [Boolean, String],
                default: 'top',
                validator(value) {
                    return [false, 'top', 'center', 'bottom'].includes(value)
                }
            },

            'space-between': {
                type: Boolean
            },

            rules: {
                type: String
                // supports a comma seperated syntax of widths for each slot item
                // for example: "50%, auto, 50px"
                // would result in each item getting those rules in order
                // (first 50%, second auto, third 50px)
            }
        },

        computed: {
            ruleList() {
                const rules = this.rules

                return rules === undefined ?
                       [] :
                       rules
                           .split(',')
                           .map(function(string) {
                               return string.trim()
                           })
            }
        },

        render(create) {
            const {tag, align, spaceBetween, rules, ruleList} = this

            const classList = {
                'atom-flex': true,
                ...(align && {['align-' + align]: true}),
                'space-between': spaceBetween
            }

            let slot = this.$slots.default ?
                       this.$slots.default.filter(isContent) :
                       []

            if (rules) {
                ruleList.forEach(function(rule, index) {
                    let item = slot[index]

                    if (rule && item !== undefined) {
                        if (isTextNode(item) && rule !== 'auto') {
                            throw new Error(
                                `Invalid rule "${rule}" for text node ` +
                                `with content "${item.text.trim()}". ` +
                                `Only "auto" is supported for text nodes.`
                            )
                        }

                        // https://vuejs.org/v2/guide/render-function.html#The-Data-Object-In-Depth
                        if (item.data === undefined) {
                            item.data = {}
                        }

                        if (item.data.style === undefined) {
                            item.data.style = {}
                        }

                        item.data.style['width'] = rule
                    }
                })
            }

            return create(tag, {class: classList}, slot)
        }
    }
</script>



<style>
    .atom-flex {
        display: flex;
    }

    .atom-flex.align-top {
        align-items: start;
    }

    .atom-flex.align-center {
        align-items: center;
    }

    .atom-flex.align-bottom {
        align-items: end;
    }

    .atom-flex.space-between {
        justify-content: space-between;
    }


    .atom-flex > * {
        width: 100%;
    }


    .atom-flex > * + * {
        margin-left: 12px;
    }
</style>
