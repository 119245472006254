<template>
    <!-- v-bind="$attrs" is needed, else :value="value" is not passed automatically -->
    <input class="atom-input" :class="{grey}" v-bind="$attrs" v-on="listeners">
</template>



<script>
    export default {
        props: {
            grey: {
                type: Boolean
            }
        },

        methods: {
            clear() {
                this.$el.value = ''
                this.$emit('input', '')
            }
        },

        computed: {
            listeners() {
                return {
                    ...this.$listeners,

                    input: event => {
                        this.$emit('input', event.target.value)
                    }
                }
            }
        }
    }
</script>



<style>
    .atom-input {
        width: 100%;
        border: none;
        box-shadow: none;

        caret-color: var(--red);

        color: var(--black);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;

        outline: none;

        padding: 12px 8px 14px 16px;

        box-sizing: border-box;

        border-radius: 12px;
        background-color: white;

        transition: background-color 0.15s;
    }

    .atom-input.grey {
        background-color: var(--super-light-grey);
    }

    .atom-input.grey:hover {
        background-color: var(--super-light-grey-hover);
    }

    .atom-input.grey:active {
        background-color: var(--super-light-grey-pressed);
    }


    .atom-input::placeholder {
        opacity: 1;

        color: var(--dark-grey);
        font-weight: 400;
    }
</style>
