<template>
    <component :is="computedTag" class="atom-label" :class="{small}">
        <template v-if="text">
            {{text}}
        </template>

        <slot/>
    </component>
</template>


<script>
    import {hasOnlyText} from '../utilities/slot.js'

    export default {
        props: {
            tag: {
                type: String,
                default: 'label',
                validator(tag) {
                    const tags = [
                        'label', // the default when passed elements in slot
                        'legend',
                        'span', // the default when passed only text as prop or slot
                        'h1',
                        'h2',
                        'h3'
                    ]

                    return tags.includes(tag)
                }
            },

            text: {
                type: [String, Number]
            },

            small: {
                type: Boolean
            }
        },

        computed: {
            computedTag() {
                const slot = this.$slots.default
                const props = this.$options.propsData

                const isDefault = props.tag === undefined

                const isOnlyText = this.text && slot === undefined ?
                                   true :
                                   hasOnlyText(slot)

                return isDefault && isOnlyText ?
                       'span' :
                       this.tag // the default from props
            }
        }
    }
</script>



<style>
    .atom-label {
        display: flex;
        flex-direction: column;

        color: var(--black);
        font-size: 20px;
        font-weight: 800;
        line-height: 26px;

        margin: 24px 8px 12px;
    }

    .atom-label.small {
        margin: 8px;

        color: var(--dark-grey);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }


    .atom-label .molecule-text-input,
    .atom-label .molecule-phone-input,
    .atom-label .molecule-time-input,
    .atom-label .molecule-file-input {
        margin-top: 8px;

        margin-left: -8px;
        margin-right: -8px;
    }


    .atom-label .atom-text {
        margin-left: 0;
        margin-right: 0;
    }

    .atom-label .atom-text:last-child {
        margin-bottom: 16px;
    }
</style>
