<template>
    <fieldset class="molecule-rating-input"
              :class="[value ? ('value-' + value) : undefined]"
              aria-label="Select a rating between 1 and 5"
              :disabled="disabled"
              v-on:keydown.left="onLeftArrowKey"
              v-on:keydown.right="onRightArrowKey">

        <label v-for="number in 5" :class="{selected: value >= number}">
            <atom-icon icon="star" :color="value >= number ? 'white' : 'black'" />

            <input type="radio" name="rating"
                   :value="number"
                   :required="required"
                   :checked="value === number"
                   @change="change(number)">
        </label>
    </fieldset>
</template>



<script>
    export default {
        props: {
            required: {
                type: Boolean
            },

            disabled: {
                type: Boolean
            },

            selected: {
                type: Number,
                validator(value) {
                    return value >= 1 && value <= 5
                }
            }
        },

        model: {
            prop: 'selected',
            event: 'change'
        },

        data() {
            return {
                value: undefined
            }
        },

        created() {
            if (this.selected) {
                this.value = this.selected
            }
        },

        methods: {
            change(value) {
                this.value = value
                this.$emit('change', value)
            },

            onLeftArrowKey(event) {
                if (this.selected === 1) {
                    event.preventDefault()
                }
            },

            onRightArrowKey(event) {
                if (this.selected === 5) {
                    event.preventDefault()
                }
            }
        }
    }
</script>



<style>
    .molecule-rating-input {
        padding: 0;
        border: none;

        display: flex;

        margin: 0 4px 12px;
    }

    .molecule-rating-input label {
        cursor: pointer;

        display: grid;

        padding: 12px;

        background-color: var(--super-light-grey);
        border-radius: 12px;

        transition: background-color 0.15s;
    }

    .molecule-rating-input label:hover {
        background-color: var(--super-light-grey-hover);
    }

    .molecule-rating-input label:active {
        background-color: var(--super-light-grey-pressed);
    }

    .molecule-rating-input.value-1 label.selected {
        background-color: var(--red);
    }

    .molecule-rating-input.value-2 label.selected,
    .molecule-rating-input.value-3 label.selected {
        background-color: var(--yellow);
    }

    .molecule-rating-input.value-4 label.selected,
    .molecule-rating-input.value-5 label.selected {
        background-color: var(--green);
    }

    .molecule-rating-input label + label {
        margin-left: 8px;
    }


    .molecule-rating-input .atom-icon {
        grid-row: 1;
        grid-column: 1;
        pointer-events: none;
    }


    .molecule-rating-input input {
        grid-row: 1;
        grid-column: 1;
        z-index: -1;

        margin: 0;
    }
</style>
