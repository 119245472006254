<script>
    import logo from '../assets/logo.svg'

    export default {
        render(create) {
            const containerString = logo.substring(0, (logo.indexOf('>') + 1))

            // possible because svg is xml
            const attributes = Object.fromEntries(
                containerString
                    .replace('<svg', '')
                    .replace('>', '')
                    .trim()
                    .split('" ')
                    .map(function(string) {
                        return string
                            .replace(/"/g, '')
                            .split('=')
                    })
                    .filter(function([key]) {
                        return key !== 'xmlns'
                    })
            )

            const innerHTML = logo
                .replace(containerString, '')
                .replace('</svg>', '')

            return create('svg', {
                class: 'atom-logo',

                domProps: {
                    innerHTML
                },

                attrs: {
                    ...attributes,
                    'aria-hidden': true
                }
            })
        }
    }
</script>
