<template>
    <div class="molecule-text-input" :class="{icon, clearable}">
        <atom-icon v-if="icon" :icon="icon" color="grey"/>

        <atom-input v-bind="$attrs" v-on="$listeners"
                    ref="input"
                    :name="name" :type="type"/>

        <button v-if="clearable" class="clear-field"
                type="button" @click="clear"
                :aria-label="'Clear ' + name">
            <atom-icon icon="cross-filled" color="grey"/>
        </button>
    </div>
</template>



<script>
    export default {
        inheritAttrs: false,

        props: {
            name: {
                type: String
            },

            type: {
                type: String,
                default: 'text',
                validator(value) {
                    return ['text', 'email', 'password'].includes(value)
                }
            },

            icon: {
                type: String
            },

            clearable: {
                type: Boolean
            }
        },

        methods: {
            clear() {
                this.$refs.input.clear()
            }
        },

        created() {
            if (this.clearable && this.name === undefined) {
                throw new Error('Missing required prop "name" with prop "clearable"')
            }
        }
    }
</script>



<style>
    .molecule-text-input {
        display: flex;

        margin-bottom: 12px;
    }


    .molecule-text-input.icon .atom-input {
        padding-left: 40px;
    }

    .molecule-text-input.clearable .atom-input {
        padding-right: 38px;
    }


    .molecule-text-input > .atom-icon:first-child {
        pointer-events: none;

        z-index: 1;
        margin-right: -36px;
        padding-left: 12px;

        margin-top: auto;
        margin-bottom: auto;
    }


    .molecule-text-input .clear-field {
        appearance: none;
        border: 0;
        padding: 0;
        background-color: transparent;

        cursor: pointer;

        padding: 4px;

        margin-left: -36px;
        padding-right: 8px;
    }


    .molecule-text-input .clear-field .atom-icon {
        display: block;
    }
</style>
