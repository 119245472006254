<template>
    <div class="molecule-phone-input" :class="{grey}">
        <label class="country-code">
            <select name="country-code"
                    :disabled="disabled"
                    :value="value.countryCode"
                    @change="updateCountryCode($event.target.value)"
                    required
                    aria-label="Select country code">

                <option v-for="{code, country, emoji} in options" :value="code">
                    <span aria-hidden="true">{{emoji}}&nbsp;&nbsp;</span>{{country}} ({{code}})
                </option>
            </select>

            <span class="selected" aria-hidden="true">
                {{selected && selected.emoji}}
                <atom-icon icon="drop-down" color="grey" />
            </span>
        </label>

        <label class="phone-input">
            <atom-text aria-hidden="true" :text="value.countryCode"/>

            <atom-input v-bind="$attrs"
                        :disabled="disabled"
                        :value="phone"
                        @input="updatePhone"
                        :type="type" :minlength="minlength"
                        :grey="grey"/>
        </label>
    </div>
</template>



<script>
    const countryCodes = [
        {code: '+47', country: 'Norway', emoji: '🇳🇴'},
        {code: '+46', country: 'Sweden', emoji: '🇸🇪'},
        {code: '+45', country: 'Denmark', emoji: '🇩🇰'},
        {code: '+49', country: 'Germany', emoji: '🇩🇪'},
        {code: '+44', country: 'UK', emoji: '🇬🇧'},
        {code: '+1', country: 'US', emoji: '🇺🇸'},
        {code: '+86', country: 'China', emoji: '🇨🇳'},
        {code: '+852', country: 'Hong Kong', emoji: '🇭🇰'},
        {code: '+65', country: 'Singapore', emoji: '🇸🇬'},
        {code: '+61', country: 'Australia', emoji: '🇦🇺'},
        {code: '+91', country: 'India', emoji: '🇮🇳'},
        {code: '+31', country: 'Netherlands', emoji: '🇳🇱'},
        {code: '+351', country: 'Portugal', emoji: '🇵🇹'}
    ]

    const defaultCountryCode = countryCodes[0].code

    export default {
        inheritAttrs: false,

        props: {
            type: {
                type: String,
                default: 'tel',
                validator(value) {
                    return value === 'tel'
                }
            },

            minlength: {
                type: Number,
                default: 5
            },

            disabled: {
                type: Boolean
            },

            grey: {
                type: Boolean
            },

            value: {
                type: Object,
                default() {
                    return {countryCode: undefined, phone: undefined}
                },
                validator(value) {
                    return value.hasOwnProperty('countryCode') &&
                           value.hasOwnProperty('phone')
                }
            }
        },

        computed: {
            options() {
                return countryCodes
            },

            phone() {
                return this.value.phone === undefined ?
                       undefined :
                       this.value.phone.replace(this.value.countryCode, '')
            },

            selected() {
                const {options, value} = this

                return options.find(function(item) {
                    return item.code === value.countryCode
                })
            }
        },

        methods: {
            updateCountryCode(value) {
                const previous = this.value.countryCode

                const countryCode = value
                const phone = this.value.phone === undefined ?
                                  undefined:
                                  this.value.phone === '' ?
                                      '' :
                                      (countryCode + this.value.phone.replace(previous, ''))

                this.$emit('change', {countryCode, phone})
            },

            updatePhone(value) {
                const countryCode = this.value.countryCode
                const phone = value === undefined ?
                                  undefined :
                                  value === '' ?
                                      '' :
                                      (countryCode + value)

                this.$emit('change', {countryCode, phone})
            }
        },

        created() {
            if (this.value.countryCode === undefined) {
                this.updateCountryCode(defaultCountryCode)
            }
        }
    }
</script>



<style>
    .molecule-phone-input {
        display: flex;

        margin-bottom: 12px;
    }


    .molecule-phone-input .country-code {
        cursor: pointer;

        flex-shrink: 0;
        width: 72px;

        display: grid;
        margin-right: 12px;

        overflow: hidden;

        border-radius: 16px;
        background-color: white;

        transition: background-color 0.15s;
    }

    .molecule-phone-input.grey .country-code {
        background-color: var(--super-light-grey);
    }

    .molecule-phone-input.grey .country-code:hover {
        background-color: var(--super-light-grey-hover);
    }

    .molecule-phone-input.grey .country-code:active {
        background-color: var(--super-light-grey-pressed);
    }



    .molecule-phone-input .country-code .selected,
    .molecule-phone-input .country-code select {
        grid-row: 1;
        grid-column: 1;
    }


    .molecule-phone-input .country-code select {
        width: 100%;

        -webkit-appearance: none;
        appearance: none;

        box-shadow: none;
        outline: none;
        border: none;

        font-family: inherit;
        font-size: inherit;
    }


    .molecule-phone-input .country-code .selected {
        z-index: 1;

        pointer-events: none;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-right: 8px;
        padding-left: 16px;

        background-color: inherit;
    }


    .molecule-phone-input .phone-input {
        width: 100%;

        display: flex;

        padding-left: 16px;

        border-radius: 12px;
        background-color: white;

        transition: background-color 0.15s;
    }

    .molecule-phone-input .phone-input .atom-text {
        margin: 0;
        padding: 12px 0 14px;
    }

    .molecule-phone-input.grey .phone-input {
        background-color: var(--super-light-grey);
    }

    .molecule-phone-input.grey .phone-input:hover {
        background-color: var(--super-light-grey-hover);
    }

    .molecule-phone-input.grey .phone-input:active {
        background-color: var(--super-light-grey-pressed);
    }

    .molecule-phone-input .atom-input {
        background-color: transparent !important;
        margin-left: 6px;
        padding-left: 10px;
    }
</style>
