<script>
    // https://router.vuejs.org/api/#router-link

    export default {
        props: {
            to: {
                type: [String, Object],
                required: true
            },

            external: {
                type: Boolean
            },

            replace: {
                type: Boolean
            },

            exact: {
                type: Boolean
            },

            activeClass: {
                type: String
            },

            text: {
                type: String
            }
        },

        computed: {
            computedTo() {
                const {to, external} = this

                return external || typeof to === 'object' || to === '#' ?
                       to :
                       {name: to}
            }
        },

        render(create) {
            const to = this.computedTo
            const {external, replace, exact, activeClass, text} = this

            const tag = external ? 'a' : 'router-link'

            const attributes = (
                external ? {
                    domProps: {
                        href: to,
                        target: replace ? undefined : '_blank'
                    }
                } : {
                    props: {
                        to,
                        replace,
                        exact,
                        activeClass
                    }
                }
            )

            return create(tag, {class: 'atom-link', ...attributes}, [
                this.text,
                this.$slots.default
            ])
        }
    }
</script>



<style>
    .atom-link {
        text-decoration: none;

        color: var(--red);
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;

        transition: color 0.15s;
    }

    .atom-link:hover {
        color: var(--red-hover);
    }

    .atom-link:active {
        color: var(--red-pressed);
    }
</style>
