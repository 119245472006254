<template>
    <img class="atom-company-logo" :class="{large}" :src="url" aria-hidden="true"/>
</template>



<script>
    import placeholder from '../assets/company-logo-placeholder.png'

    export default {
        props: {
            url: {
                type: String,
                default: placeholder
            },

            large: {
                type: Boolean
            }
        }
    }
</script>



<style>
    .atom-company-logo {
        max-width: 40px;
        max-height: 40px;

        border-radius: 12px;
    }

    .atom-company-logo.large {
        max-width: 100px;
        max-height: 100px;
    }
</style>
