<template>
    <dialog class="atom-dialog" v-on="$listeners">
        <slot/>
    </dialog>
</template>



<script>
    import dialogPolyfill from 'dialog-polyfill'

    export default {
        props: {
            backdrop: {
                type: Boolean
            }
        },

        methods: {
            close() {
                this.$el.close()
            }
        },

        mounted() {
            dialogPolyfill.registerDialog(this.$el)

            // "Backdrop is only displayed when dialog is opened with dialog.showModal()"
            //  - https://developer.mozilla.org/en-US/docs/Web/CSS/::backdrop
            this.backdrop ?
                this.$el.showModal() :
                this.$el.show()
        }
    }
</script>



<style>
    @import 'dialog-polyfill/dist/dialog-polyfill.css';

    /* polyfill */
    .atom-dialog + .backdrop {
        opacity: 0.48;
        background-color: #000000;
    }

    /* native */
    .atom-dialog::backdrop {
        opacity: 0.48;
        background-color: #000000;
    }
</style>
