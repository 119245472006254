<template>
    <svg class="atom-gauge" aria-hidden="true" :fill="computedColor" width="100" height="4">
        <rect :width="filled + '%'" height="100%" rx="2"/>
    </svg>
</template>



<script>
    const globalStyles = window.getComputedStyle(document.documentElement)

    export default {
        props: {
            filled: {
                type: Number,
                validator(value) {
                    return value >= 0 && value <= 100
                }
            },

            color: {
                type: String,
                default: 'black',
                validator(value) {
                    return globalStyles.getPropertyValue('--' + value) ? true : false
                }
            }
        },

        computed: {
            computedColor() {
                return globalStyles.getPropertyValue('--' + this.color)
            }
        }
    }
</script>



<style>
    .atom-gauge {
        display: block;
        width: 100%;

        border-radius: 5px;
        background-color: var(--light-grey);

        margin: 12px 0 20px;
    }
</style>
