<template>
    <component :is="tag" class="atom-card">
        <slot/>
    </component>
</template>



<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'div',
                validator(tag) {
                    return ['div', 'section', 'article'].includes(tag)
                }
            },
        }
    }
</script>



<style>
    .atom-card {
        display: flex;
        flex-direction: column;

        padding: 16px 16px 0;
        background-color: white;
        border-radius: 16px;

        margin-bottom: 12px;
    }


    .atom-card .atom-title:first-child {
        margin-top: 8px;
        margin-bottom: 24px;
    }

    .atom-card span.atom-title:first-child {
        margin: 0;
    }


    .atom-card time.atom-text {
        margin-bottom: 0;
    }


    .atom-card .atom-player {
        margin: 8px 0px 32px 0px;
    }
</style>
