<template>
    <svg class="atom-icon" aria-hidden="true"
         width="24" height="24" viewBox="0 0 24 24"
         :fill="fill"
         v-html="innerHTML"/>
</template>



<script>
    import * as exported from '../assets/icons/index.js'

    import {mapKeys} from '../utilities/object.js'
    import {kebabCase} from '../utilities/string.js'

    const icons = mapKeys(exported, kebabCase)

    const globalStyles = window.getComputedStyle(document.documentElement)

    export default {
        props: {
            icon: {
                type: String,
                required: true,
                validator(value) {
                    return icons.hasOwnProperty(value)
                }
            },

            color: {
                type: String,
                default: 'black',
                validator(value) {
                    return globalStyles.getPropertyValue('--' + value) ? true : false
                }
            }
        },

        computed: {
            fill() {
                return globalStyles.getPropertyValue('--' + this.color)
            },

            innerHTML() {
                const svg = icons[this.icon]
                const containerString = svg.substring(0, (svg.indexOf('>') + 1))

                return svg
                    .replace(containerString, '')
                    .replace('</svg>', '')
            }
        }
    }
</script>



<style>
    .atom-icon {
        display: block;
    }
</style>
