<template>
    <header class="molecule-header"
            :class="[title ? ('align-' + align) : '', {'with-title': title}]">

        <slot name="left">
            <atom-logo/>
        </slot>

        <atom-title v-if="title && label === undefined"
                    size="small" :text="title"/>

        <div v-else-if="title && label">
            <atom-title size="small" center italic :text="title"/>
            <atom-text tag="small" center small light :text="label"/>
        </div>

        <slot name="right"/>
    </header>
</template>



<script>
    export default {
        props: {
            title: {
                type: String
            },

            label: {
                type: String
            },

            align: {
                type: String,
                default: 'center',
                validator(value) {
                    return ['center', 'left'].includes(value)
                }
            }
        }
    }
</script>



<style>
    .molecule-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 12px 16px;
    }

    .molecule-header.with-title.align-center {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        column-gap: 16px;
    }

    .molecule-header.with-title.align-center > :nth-child(3) {
        justify-self: end;
    }


    .molecule-header.with-title.align-left > :nth-child(2) {
        margin-left: 16px;
        margin-right: auto;
    }


    .molecule-header .atom-logo {
        margin: 10px 0 10px 8px;
    }


    .molecule-header .atom-title,
    .molecule-header .atom-text {
        margin: 0;
    }
</style>
